<template>
  <div class="page page__error error-page">
    <h1 class="error-page__title">
      {{ errorMessage }}
    </h1>

    <div class="error-page__code">
      {{ errorCode | upperCase }}
    </div>

    <div class="error-page__buttons">
      <esmp-button
        view="outline"
        @click="goToMainPage"
      >
        Перейти на главную
      </esmp-button>

      <esmp-button
        v-if="actionButtonMethod"
        @click="actionButtonMethod"
      >
        {{ actionButtonText }}
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Error',

  props: {
    errorCode: {
      type: String,
      default: '404',
    },
  },

  data() {
    return {
      errorMessage: '',
      actionButtonText: '',
      actionButtonMethod: null,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapActions('auth', ['login', 'logout']),

    init() {
      switch (this.errorCode) {
      case '401':
      case '403':
        this.errorMessage = 'Ошибка авторизации';
        this.actionButtonText = 'Попробовать снова';
        this.actionButtonMethod = this.login;
        break;

      case 'oidc':
        this.errorMessage = 'Ошибка авторизации OIDC';
        this.actionButtonText = 'Попробовать снова';
        this.actionButtonMethod = this.login;
        break;

      case '404':
        this.errorMessage = 'Ой-ой... Страница не найдена';
        this.actionButtonText = 'Выйти';
        this.actionButtonMethod = this.logout; // TODO: проверить, сейчас ошибка
        break;

      default:
        this.errorMessage = 'Что-то пошло не так';
        this.actionButtonText = 'Выйти';
        this.actionButtonMethod = this.logout;
        break;
      }
    },

    goToMainPage() {
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style lang="scss">
.error-page {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  padding: 40px 0;
  position: relative;
  overflow: hidden;

  &__code {
    font-size: 35vw;
    line-height: 1;
    font-weight: 600;
    color: #d8d4ea;
    text-shadow: (4px 4px 8px rgba(29, 20, 49, 0.08));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__buttons {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    position: absolute;
    bottom: 5%;
    right: 5%;
    transform: translate(50%, 50%);
    background: radial-gradient(#7b66ff, $color-client-portal-logo);
    z-index: 0;
    user-select: none;
    pointer-events: none;

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }
}
</style>
